@font-face {
    font-family: "Avenir";
    src: url("../src/fonts/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf");
    font-display: swap;
  }
  
  @font-face {
    font-family: "Avenir-light";
    src: url("../src/fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf");
    font-display: swap;
  }
  
  @font-face {
    font-family: "PierSans-Medium";
    src: url("../src/fonts/PierSans-Medium.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: "PierSans-Bold";
    src: url("../src/fonts/PierSans-Bold.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: "PierSans-Regular";
    src: url("../src/fonts/PierSans-Regular.otf");
    font-display: swap;
  }
  
  @font-face {
    font-family: "SFProDisplay-Regular";
    src: url("../src/fonts/SF-Pro-Text-Regular.ttf");
    font-display: swap;
  }
  
  

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,600,600i,700,700i,800,800i&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');


html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
*,
*:before,
*:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

.ru-font {
	font-family: 'Rubik', sans-serif;
}

body {
	font-family: 'Montserrat', sans-serif;
	background-color: #f5f6fa;
	scrollbar-width: thin;
}

html {
	scrollbar-width: thin;
}

button,
a {
	cursor: pointer;
	outline: none;
}

button:focus,
a:focus {
	outline: 0px;
}
.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}

@media (min-width: 920px) {
	.container {
		width: 850px;
	}
}

@media (min-width: 992px) {
	.container {
		width: 970px;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
}

@media (min-width: 1920px) {
	.container {
		width: 1670px;
	}
}

.main-btn {
	background: #1083f1;
	background-image: linear-gradient(45deg, #237ac6, #04b395);
	border-radius: 24px;
	color: #fff;
	border: 0px;
	outline: 0px;
	font-family: Rubik, sans-serif;
	font-weight: 400;
	padding: 8px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@-webkit-keyframes showpopbackindrop {
	from {
		background: rgba(6, 7, 47, 0.4);
	}

	to {
		background: rgba(6, 7, 47, 0.8);
	}
}

@keyframes showpopbackindrop {
	from {
		background: rgba(6, 7, 47, 0.4);
	}

	to {
		background: rgba(6, 7, 47, 0.8);
	}
}

/* Tool tips */
.cm-up-tooltip-ic {
	visibility: hidden;
	width: auto;
	background-color: #2c3956;
	color: rgb(255 255 255/0.85);
	text-align: center;
	border-radius: 6px;
	padding: 12px 14px;
	position: absolute;
	z-index: 1;
	top: -50px;
	left: -36px;
	font-size: 12px;
	opacity: 0;
	min-width: 100px;
	font-weight: 400;
	font-family: Rubik, sans-serif !important;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.cm-up-tooltip-ic::after {
	content: '';
	position: absolute;
	margin-left: -5px;
	border: 8px solid transparent;
	border-bottom-color: #2c3956;
	border-radius: 4px;
	bottom: -14px;
	left: 50%;
	transform: rotate(-180deg);
}

/*custom radio inputs */

/* The container */
.radio-cont {
	display: block;
	position: relative;
	padding-left: 32px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */
.radio-cont input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
	position: absolute;
	top: -3px;
	left: 0;
	height: 25px;
	width: 25px;
	background: rgba(216, 216, 216, 0);
	border: 1px solid #4d5767;
	border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-cont:hover input ~ .radio-checkmark {
	background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-cont input:checked ~ .radio-checkmark {
	background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-cont input:checked ~ .radio-checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.radio-cont .radio-checkmark:after {
	top: 4px;
	left: 4px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #4d5767;
}

.button-disabled {
	background: #c7cefc !important;
	border: 1px solid #c7cefc !important;
	cursor: default !important;
}

/* .button-disabled:hover {
  background: #c7cefc !important;
  transition: none !important;
} */

.skeleton-loading {
	background-image: linear-gradient(90deg, #f4f4f4 0px, rgba(229, 229, 229, 0.8) 40px, #f4f4f4 80px) !important;
	background-size: 600px;
	animation: shine-lines 2s infinite ease-out;
}

.skeleton-loadinga {
	background-image: linear-gradient(90deg, #ebf1f7, #ffffff, #ebf1f7) !important;
	background-size: 600px;
	animation: shine-lines 1s linear 0s infinite;
}

.timing-table button.skeleton-loadinga {
	pointer-events: none;
	color: transparent;
}

.short-info p.skeleton-loadinga {
	width: 100%;
	height: 20px;
	color: transparent !important;
}

h4.skeleton-loadinga {
	width: 50%;
	height: 20px;
	color: transparent !important;
}

.table-time-cont h4.skeleton-loadinga {
	width: 100%;
}

h4.skeleton-loadinga .today-highlight {
	display: none;
}

h4.skeleton-loading {
	width: 50%;
	height: 20px;
}

p.skeleton-loading {
	width: 100%;
	height: 20px;
}

button.skeleton-loading {
	display: none !important;
}

@keyframes shine-lines {
	0% {
		background-position: -100px;
	}

	40%,
	100% {
		background-position: 540px;
	}
}

@-webkit-keyframes com_pop_backin {
	from {
		background: rgba(6, 7, 47, 0.4);
	}

	to {
		background: rgba(6, 7, 47, 0.8);
	}
}

@keyframes com_pop_backin {
	from {
		background: rgba(6, 7, 47, 0.4);
	}

	to {
		background: rgba(6, 7, 47, 0.8);
	}
}
