.myact-addnew-btn-desk {
	background-color: transparent;
	box-shadow: none;
	outline: 0px;
	border: 1px solid #465dce;
	color: #465dce;
	font-size: 13px;
	font-weight: 500;
	border-radius: 4px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 15px;
	transition: all 1s;
}

button.myact-chgecard-btn {
	font-size: 12px;
	color: #465dce;
	letter-spacing: 0.23px;
	border: 0;
	background: transparent;
	box-shadow: none;
	outline: 0;
	font-weight: 400;
	position: absolute;
	right: 0px;
	padding: 0px;
}

.myact-change-card-desk {
	position: absolute;
	top: 26px;
	width: 220px;
	background: #fff;
	z-index: 1;
	box-shadow: 0 1px 23px 0 hsla(0, 0%, 67.8%, 0.5);
	right: -45px;
	border-radius: 12px;
	padding: 20px;
}

.myact-change-card-desk::before {
	content: '';
	width: 0;
	height: 0;
	left: 44%;
	border-color: transparent transparent #fff #fff;
	top: -15px;
	position: absolute;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 15px solid #fff;
}

.pop-up-mobile-btm {
	display: none;
}

.myact-popup-mb-header {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.myact-popup-mb-header h2 {
	font-size: 14px;
	font-weight: 500;
	color: #242248;
	text-align: left;
	width: 100%;
}
.myact-close-popup-btn {
	background-color: transparent;
	background: transparent;
	border: 0px;
	outline: 0px;
	position: absolute;
	right: 0;
	padding: 0;
}

.myact-close-popup-btn img {
	width: 14px;
}
.myact-ok-img.myact-default-card-selected {
	background-color: #343063;
}
.myact-ok-img img {
	width: 10px;
}
.myact-change-this-c p {
	font-size: 12px;
	font-weight: 400;
	margin: 0;
	width: 100%;
	display: flex;
	position: relative;
	align-items: center;
	color: #767588;
	height: 20px;
}

.myact-addnew {
	margin: 25px 0px;
	font-size: 12px;
}

h4.def-click-card {
	font-weight: 400 !important;
	width: 100% !important;
	display: flex !important;
	align-items: center !important;
	font-size: 13px !important;
}
h4.def-click-card img {
	width: 28px !important;
	left: -2px !important;
	position: relative !important;
}
h4.def-click-card b {
	margin-left: 2px !important;
	font-weight: 600 !important;
}
.myact-change-card {
	width: 100%;
	margin: 15px 0px;
	/* max-width: 185px; */
}
.myact-addnew-card-cont {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding: 0px 15px;
	position: fixed;
	bottom: 10px;
	left: 0;
	opacity: 0;
	animation: myact-appear 0.3s linear 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	/* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

.myact-addnew-card-btn {
	color: #576ce4 !important;
	font-size: 14px;
	font-weight: 500;
	border: 1px solid #576ce4;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	background-color: #fff;
	border-radius: 4px;
}
.myact-addnew .stripe-error {
	font-size: 13.5px;
	font-weight: 500;
	color: #a51f1f;
}
.myact-addnew-card p {
	cursor: pointer;
}
.myact-change-card .myact-change-this-c:last-child {
	margin-bottom: 60px;
}
.myact-change-this-c {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
}
.myact-change-this-c p {
	cursor: pointer;
}
.myact-card-type {
	display: block;
	width: 70px;
}
.myact-ok-img {
	width: 16px;
	height: 16px;
	text-align: left;
	background-color: #e9e8ef;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 2px;
}

.mb-pop-up-slide-from-btm {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.6);
	-webkit-animation: back-slide-in 0.5s linear;
	animation: back-slide-in 0.5s linear;
	z-index: 9999;
	overflow: hidden;
	touch-action: none;
	overscroll-behavior-y: none;
}
.dash-more-mb-popup.mb-pop-up-slide-from-btm {
	background: rgba(0, 0, 0, 0.6) !important;
}

.dash_renew_card_pop .sfb-upper-blank-part {
	background-color: transparent;
}
.dash_renew_card_pop.mb-pop-up-slide-from-btm {
	background-color: rgba(6, 7, 47, 0.82);
}

.sfb-upper-blank-part {
	position: absolute;
	width: 100%;
	height: 100vh;
	opacity: 0.8;
	background: #06072f;
}
.dash-more-mb-popup .sfb-upper-blank-part {
	background: transparent;
}

.sfb-main-part {
	width: auto;
	padding: 20px;
	min-width: 375px;
	min-height: 285px;
	height: auto;
	background: #fff;
	position: relative;
	border-radius: 4px;
}
.sfb-main-part h4 {
	font-size: 14px;
	color: #000000;
	line-height: 22px;
	margin: 10px 0px 20px;
	font-weight: 500;
	letter-spacing: -0.41px;
}

@media (max-width: 768px) {
	.myact-change-card-desk {
		display: none;
	}
	/* pop-up-common-mobile*/
	.pop-up-mobile-btm,
	.mb-pop-up-slide-from-btm {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		display: block;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 11, 75, 0.6);
		-webkit-animation: back-slide-in 0.5s linear;
		animation: back-slide-in 0.5s linear;
		z-index: 9999;
		overflow: hidden;
		touch-action: none;
		overscroll-behavior-y: none;
	}
	.mb-pop-up-slide-from-btm {
		background-color: transparent;
	}
	.mb-pop-up-slide-from-btm.ps {
		background-color: rgba(0, 11, 75, 0.8);
	}
	.mb-pop-up-slide-from-btm .sfb-main-part.cancel-sfb-main {
		padding-top: 25px;
	}
	.pop-up-mobile-btm .upper-blank-part {
		height: 40%;
	}
	.mb-pop-up-slide-from-btm .sfb-upper-blank-part {
		height: 50%;
		position: relative;
	}
	.mb-pop-up-slide-from-btm .sfb-upper-blank-part.per20 {
		height: 20%;
		background-color: transparent;
	}
	.pop-up-mobile-btm .upper-blank-part-vp {
		height: 52%;
	}

	.mb-pop-up-slide-from-btm .sfb-main-part {
		width: 100%;
		height: 50%;
		background: white;
		position: relative;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		padding: 20px;
		min-width: auto;
		min-height: auto;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-animation: slide-in-form-bottom 0.5s linear;
		animation: slide-in-form-bottom 0.5s linear;
	}
	.mb-pop-up-slide-from-btm .sfb-main-part.per80 {
		height: 80%;
	}

	.pop-up-mobile-btm .main-part {
		width: 100%;
		height: 60%;
		background: white;
		position: relative;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		padding: 20px;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-animation: slide-in-form-bottom 0.5s linear;
		animation: slide-in-form-bottom 0.5s linear;
	}
	.pop-up-mobile-btm .main-part-vp {
		width: 100%;
		height: 48%;
		background: white;
		position: fixed;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		padding: 20px;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-animation: slide-in-form-bottom-minus 0.5s linear;
		animation: slide-in-form-bottom-minus 0.5s linear;
		bottom: 0;
	}
}

@keyframes slide-in-form-bottom {
	0% {
		top: 100%;
	}

	100% {
		top: 0;
	}
}
@keyframes slide-in-form-bottom-minus {
	0% {
		bottom: -100%;
	}

	100% {
		bottom: 0;
	}
}
@-webkit-keyframes back-slide-in {
	0% {
		background: rgba(0, 0, 0, 0);
	}

	100% {
		background: rgba(0, 0, 0, 0.6);
	}
}

/* Standard syntax */
@keyframes back-slide-in {
	0% {
		background: rgba(0, 0, 0, 0);
	}

	100% {
		background: rgba(0, 0, 0, 0.6);
	}
}

@-webkit-keyframes myact-appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes myact-appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* commom popup mobile screens */
.c-pop-mb-new {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 99999;
}
.c-pop-mb-back-btn {
	padding: 0px;
}
.c-pop-mb-new-overlay {
	background-color: #00093a;
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
}
.c-pop-mb-new-main-cont {
	width: 45%;
	margin: auto;
	background-color: #fff;
	position: relative;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	max-height: 650px;
	align-items: center;
	justify-content: center;
}
.c-pop-mb-new-head {
	position: absolute;
	height: 50px;
	width: 100%;
	top: 0;
	display: flex;
	align-items: center;
	padding-left: 15px;
	background-color: transparent;
	z-index: 9;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.c-pop-mb-new-content {
	width: 85%;
	margin: 60px auto;
}
.c-pop-mb-new-content h4 {
	font-size: 14px;
	color: #4a4a4a;
	font-weight: 500;
}
textarea.writemess-cc {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 2px solid #ddd2e9;
	border-radius: 0.25rem;
	-webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	min-height: 130px;
	box-sizing: border-box;
}
.c-pop-mb-new-submit-btn {
	display: inline-flex;
	background-image: -webkit-gradient(linear, left top, right top, from(#564cdb), to(#706dd7));
	background-image: linear-gradient(90deg, #564cdb 0%, #706dd7 100%);
	padding: 10px 34px;
	color: #fff;
	font-size: 13px;
	border-radius: 30px;
	cursor: pointer;
	text-decoration: none;
	font-weight: 500;
	outline: 0px;
	border: 0px;
	width: auto;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.c-pop-mb-back-btn {
	background-color: transparent;
	border: 0px;
	outline: 0px;
	padding: 0;
	width: 15px;
	height: 15px;
	text-align: left;
	display: flex;
}
.fl-left {
	position: absolute;
	top: 15px;
	left: 15px;
	display: none;
}
.fl-right {
	position: absolute;
	top: 15px;
	right: 15px;
}

@media (max-width: 768px) {
	.c-pop-mb-new {
		overscroll-behavior-y: none;
		touch-action: none;
		background-color: #fff;
	}
	.c-pop-mb-new-overlay {
		display: none;
	}
	.c-pop-mb-new-main-cont {
		width: 100%;
		max-height: none;
		border-radius: 0;
		height: 100vh;
		touch-action: none;
		overflow: hidden;
		position: relative;
	}
	.c-pop-mb-new-head {
		position: fixed;
		top: 0px;
		left: 0px;
		padding: 20px 20px 0px;
		height: 40px;
		display: block;
		justify-content: center;
		background-color: #fff;
	}
	.c-pop-mb-new-content {
		margin: 0px;
		width: 100%;
		padding: 0px 20px 0px;
		display: flex;
		flex-direction: column;
		height: 100vh;
		justify-content: center;
	}
	.fl-left {
		display: block;
	}
	.fl-right {
		display: none;
	}
}
