@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
    @apply font-inter bg-white;
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
	font-family: 'Avenir';
	src: url('https://js.myyogateacher.com/fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2') format('woff2'),
		url('https://js.myyogateacher.com/fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff') format('woff');
}

@font-face {
	font-family: 'Quincy';
	src: url('https://js.myyogateacher.com/fonts/QuincyCF-Bold.eot') format('eot'),
		url('https://js.myyogateacher.com/fonts/QuincyCF-Bold.woff') format('woff'),
		url('https://js.myyogateacher.com/fonts/QuincyCF-Bold.woff2') format('woff2');
}

@layer components {
	.header {
		@apply flex justify-between items-center my-5 mx-5;
	}

	.navLink {
		@apply flex items-center;
	}

	.navItem {
		@apply text-base font-medium text-mytpurple hover:text-mytorange-hover cursor-pointer mx-8 font-inter;
	}

	.navDropDownSectionHeading {
		@apply block text-gray-500 text-sm mt-10;
	}

	.navDropDownItem {
		@apply block text-dark_purple text-15.5 font-semibold my-3 hover:text-mytorange-hover;
	}
}

@layer base {
	h1 {
		@apply lg:text-6xl lg:leading-tight zm:text-4xl sm:leading-tight zm:text-4xl zm:leading-tight font-avenir text-black font-black;
	}

	h2 {
		@apply lg:text-6xl lg:leading-tight sm:text-5xl sm:leading-tight zm:text-2xl zm:leading-tight font-avenir text-black font-black;
	}

	h3 {
		@apply sm:text-4xl zm:text-xl font-avenir text-black font-extrabold leading-normal;
	}

	h4 {
		@apply font-avenir text-lg font-extrabold leading-normal text-black;
	}

	p {
		@apply text-gray-500 text-base font-light;
	}

	.heading {
		@apply xl:text-6xl zm:text-3xl font-extrabold font-avenir not-italic;
	}

	.subHeading {
		@apply xl:text-xl zm:text-lg text-mytgrey-lite mt-4 text-mytgrey-default;
	}

	/* NEW UTILITY CLASS */


    /* Home Page CSS Classes */

    .mainHeadingHome{
        @apply lg:text-70px lg:font-bold zm:font-extrabold zm:text-40px font-quincy;
    }

    .homeBgCard{
        @apply bg-mytBgOrangeLite py-10 px-6 lg:m-1 h-full rounded-xl text-center zm:mb-5 lg:mb-0;
    }
   
    .homeCardHeading{
        @apply my-3 mb-1 text-mytorange-default text-lg font-black font-avenir uppercase;
    }

    .homeCardSubHeading{
        @apply text-mytBrown text-15 font-normal leading-snug font-inter;
    }

    .homePressHeading{
        @apply text-center lg:text-54 zm:text-30px lg:font-bold zm:font-bold font-quincy text-white mb-10;
    }

    .homeTeacherVaultHeading{
        @apply lg:text-50px zm:text-30px font-bold font-quincy text-black;
    }

    .homeTrailSectionHeading{
        @apply lg:text-70px lg:font-bold zm:font-extrabold zm:text-36 font-quincy text-white zm:leading-9;
    }

    .homeFooterSubTitle{
        @apply text-center text-mytgrey-subtitle lg:text-xl zm:text-base font-normal font-inter;
    }

    .homeNewzBlock{
        @apply font-avenir text-left text-white font-black xl:leading-snug zm:leading-snug lg:text-22 zm:text-lg;
    }

	/* heading css */

	.contentHeading {
		/* @apply xl:text-64 zm:text-4xl font-extrabold font-avenir not-italic; */
		@apply lg:leading-tight zm:text-4xl lg:text-64 sm:leading-tight zm:leading-tight font-avenir text-black font-black tracking-normal;
	}

	.subContentHeading {
		@apply xl:text-6xl lg:leading-tight zm:text-34 font-extrabold font-avenir not-italic font-black;
	}

	.smallContentHeading {
		@apply xl:text-27 zm:text-22 font-extrabold font-avenir not-italic xl:leading-9 zm:leading-8;
	}

	.homeSmallContentHeading {
		@apply xl:text-xl zm:text-xl font-extrabold font-avenir not-italic xl:leading-9 zm:leading-8;
	}

	.classHeading {
		@apply md:text-34 zm:text-26 font-black text-mytpurple leading-normal font-avenir;
	}

	.classSubHeading {
		@apply md:text-lg zm:text-15 text-mytgrey-title font-medium font-inter;
	}

	.listHeading {
		@apply md:text-lg zm:text-base font-avenir font-black text-black;
	}

	/* container class */

	.topContainer {
		@apply relative mx-auto;
	}

	.baseContainer {
		@apply md:max-w-5.5xl md:container sm:w-full mx-auto zm:px-6 sm:px-24 xl:px-6;
	}

	/* teacher profile card css */

	.teacherCard {
		@apply bg-white p-13 rounded-md shadow-lg mt-8;
	}

	.profileImgLabel {
		@apply font-inter font-bold text-white text-11 bg-mytgreen rounded-full text-center p-3 uppercase w-24 absolute -top-4 -right-6;
	}

	.teacherHeading {
		@apply font-semibold text-lg text-black font-inter;
	}

	.teacherSubHeading {
		@apply font-semibold text-base text-black font-inter;
	}

	/* --------------- */

	/* description css */

	.contentDescription {
		@apply text-left text-mytgrey-default lg:text-xl zm:text-sm font-normal font-light font-inter zm:mt-2;
		/* text-left xl:text-lg lg:text-lg md:text-base zm:text-sm font-normal text-mytgrey-default */
	}

	.subContentDescription {
		@apply text-mytgrey-lite lg:text-17 zm:text-15 font-normal font-light font-inter xl:leading-7 zm:leading-7;
	}

	/* -------------------- */

	/* ------- card css -------- */

	.cardHeading {
		@apply text-mytLitePurpleTextDefault lg:text-2xl zm:text-22 font-avenir font-black;
	}

	.pricingLabel {
		@apply font-avenir text-mytpurple text-black md:text-5xl zm:text-42 font-black;
	}

	.pricingDuration {
		@apply md:text-15 zm:text-sm text-mytgrey-pricingDuration font-inter md:font-semibold zm:font-extrabold;
	}

	.pricingPlanDescritpion {
		@apply font-black md:text-lg zm:text-base font-avenir text-black;
	}

	.pricingBannerHeading {
		@apply md:text-4xl zm:text-2xl font-medium text-white font-avenir;
	}

	.pricingBannerText {
		@apply lg:text-84 zm:text-52 text-white font-avenir font-black;
	}

	.pricingBannerInfo {
		@apply lg:text-xl zm:text-base text-white font-inter font-medium;
	}

	.pricingFaqHeading {
		@apply md:text-xl zm:text-lg font-extrabold font-avenir not-italic xl:leading-9 zm:leading-8;
	}

	.pricingFaqDescription {
		@apply md:text-base zm:text-sm font-normal font-inter text-mytgrey-lite;
	}

	/* ----------xxxxxxx-------- */

    /* ---------Blog page class------- */

     .articalHeading{
         @apply lg:text-6xl zm:text-34 font-black text-black font-avenir text-left;
     }

     .articalaPostMainHeading{
         @apply lg:text-38 zm:text-19px font-black text-black font-avenir text-left leading-relaxed;
     }

     .aritcalPageDescription{
         @apply lg:text-base lg:font-normal zm:text-15 text-mytgrey-lite text-left font-inter;
     }

    /* ----------xxxxxxx-------- */

    /* ---------Press & Media------- */

    .pressSubHeading {
        @apply lg:text-42 zm:text-22 font-black font-avenir text-left;
    }
    .pressSmallContentHeading {
        @apply lg:text-32 zm:text-19px font-black text-black font-avenir text-left;
    }

    .pressHeadingDescription {
		@apply text-left text-mytgrey-default lg:text-xl zm:text-15 font-normal font-light font-inter zm:mt-2;
		/* text-left xl:text-lg lg:text-lg md:text-base zm:text-sm font-normal text-mytgrey-default */
	}
    .pressDescription {
        @apply lg:text-base zm:text-15 font-normal;
    }

    .logoHeading {
        @apply lg:text-27 zm:text-22 font-black font-avenir;
    }

    .logoDescription {
        @apply lg:text-17 zm:text-base font-normal;
    }


    /* ------------xxxxxxx------------ */


	.mytOrangerBorder {
		@apply border-4 bg-mytorange-lite;
	}

	.customContainer {
		@apply container px-4 mr-auto ml-auto;
	}

	.mytOrangerBorder {
		@apply border-4 bg-mytorange-lite;
	}
	.mytTextHeading {
		@apply text-2xl text-black;
	}
	.textPagesContainer {
		@apply xl:px-36 xl:py-20 zm:px-10 zm:py-10;
	}
	.textPageContentHeading {
		@apply font-avenir text-2xl mt-14 font-semibold text-black;
	}
	.textPageContentText {
		@apply xl:text-lg zm:text-lg text-mytgrey-lite mt-2 mb-5;
	}
	.trialCTABody {
		@apply btn btn-primary xl:px-5 lg:px-8 xl:py-3 zm:py-3 lg:py-4 mt-6 xl:text-lg lg:text-lg md:text-lg zm:text-sm rounded-lg;
	}

	.mainContainer {
		@apply relative mx-auto sm:mt-12 zm:mt-28 lg:mt-24 mb-16;
	}

	/* container class */

	.topContainer {
		@apply relative mx-auto;
	}

	.baseContainer {
		@apply md:max-w-1100px md:container sm:w-full mx-auto zm:px-6 sm:px-18 md:px-24 xl:px-0;
	}

	/* margin for the top container on the home page */
	.topContentContainerMarginHome {
		@apply zm:mt-28 zm:mb-24 lg:mt-40 lg:mb-52;
	}
	/* margin for the top container for the rest of the website */
	.topContentContainerMarginROW {
		@apply zm:mt-24 md:mt-48;
	}

	/* login signup pages */
	.loginSignupLeftContainer {
		@apply md:w-5/12 xl:pl-31 lg:pl-18 md:pl-8 pt-6 zm:px-5 sm:px-10 sm:py-10 flex flex-col justify-between  h-full;
	}
	.loginSignupMidSection {
		@apply max-w-loginFormContainer;
	}
	.loginSignupRightImage {
		@apply md:w-7/12 bg-mytBgLogin w-full h-screen xl:ml-16 lg:ml-16 md:ml-8 bg-cover bg-top bg-no-repeat zm:hidden md:block;
	}
	.breadcrumbBackContainer {
		@apply flex items-center  w-32 cursor-pointer zm:hidden md:flex pl-2;
	}
	.breadcrumbBackContent {
		@apply text-mytgrey-default zm:text-sm ml-3 font-semibold;
	}
	.breadcrumbBackContainerMobile {
		@apply flex justify-end items-end w-32 cursor-pointer mt-1 ml-2 zm:flex md:hidden;
	}
	.breadcrumbBackContentLogo {
		@apply zm:mt-2 md:mt-4 zm:flex md:block justify-between items-center;
	}
	.breadcrumbBackContentLogoImg {
		@apply zm:h-5 md:h-8;
	}
	.loginSignupErrorMsg {
		@apply text-mytRedText text-sm font-medium mt-2;
	}
	.loginSignupHeadingContainer {
		@apply zm:w-full;
	}
	.loginSignupHeading {
		@apply zm:text-3xl xl:text-4xl lg:text-3xl md:text-3xl font-extrabold font-avenir;
	}
	.loginSignupFormLabel {
		@apply text-mytLabel text-13.5 pl-0 font-medium;
	}
	.loginSignupBtn {
		@apply btn btn-orange zm:py-3 zm:px-5 sm:py-3 sm:px-6 mb-50px rounded-lg text-17;
	}

	/* footer CSS */
	.footerHeading {
		@apply text-xs text-mytgrey-default font-semibold leading-normal text-left text-gray-500 mb-1.5 zm:mb-0;
	}
	.footerLink {
		@apply leading-normal text-left font-semibold zm:mb-1 text-14.5 cursor-pointer;
	}

	/* right hand side floating image */
	.rightImgInSection {
		@apply absolute lg:w-2/5 sm:w-3/5 max-w-xl;
	}

	/* copyright section */
	.copyright {
		@apply zm:mt-8 zm:text-11 sm:mt-14;
	}
}

/*  custom css for new website */

.transform-50 {
	transform: translate(-50%, -50%);
}

.teacherTransform-50 {
	transform: translate(45%, 45%);
}

body {
	font-family: Inter;
	background-color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

.copyright {
	line-height: 20px;
	color: #9e9e9e;
}

.mytBorderBottom {
	border-bottom: 5px solid #ffe0d1;
}

/* css for checkbox */

.custom-checkbox {
	/* width: 100%; */
	/* margin: 15px auto; */
	position: relative;
	display: block;
}

.custom-checkbox input[type='checkbox'] {
	width: auto;
	opacity: 0.00000001;
	position: absolute;
	left: 0;
	margin-left: -20px;
}
.custom-checkbox label {
	position: relative;
}
.custom-checkbox label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	margin: 5px;
	width: 20px;
	height: 20px;
	transition: transform 0.28s ease;
	border-radius: 3px;
	/* border: 2px solid #EE731B; */
	background: #f0f0f0;
	color: #ee731b;
}
.custom-checkbox label:after {
	content: '';
	display: block;
	width: 10px;
	height: 5px;
	border-bottom: 2px solid #ee731b;
	border-left: 2px solid #ee731b;
	-webkit-transform: rotate(-45deg) scale(0);
	transform: rotate(-45deg) scale(0);
	transition: transform ease 0.25s;
	will-change: transform;
	position: absolute;
	top: 12px;
	left: 10px;
}
.custom-checkbox input[type='checkbox']:checked ~ label::before {
	color: #7bbe72;
	border: 2px solid #ee731b;
}

.custom-checkbox input[type='checkbox']:checked ~ label::after {
	-webkit-transform: rotate(-45deg) scale(1);
	transform: rotate(-45deg) scale(1);
}

.custom-checkbox label {
	min-height: 34px;
	display: block;
	padding-left: 30px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
	vertical-align: sub;
}
.custom-checkbox label span {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.custom-checkbox input[type='checkbox']:focus + label::before {
	outline: 0;
}

.testSetupPlayBtn {
	transform: translate(-50%, -50%);
	top: 50%;
	left: 76%;
}

.myt-grid {
	display: grid;
	grid-template-columns: 1fr repeat(3, 2fr) 1fr;
	grid-template-rows: 30px 90px 50px 80px 70px 90px 50px;
	gap: 25px;
}

.myt-1 {
	grid-column: 1/2;
	grid-row: 2/4;
}

.myt-2 {
	grid-column: 2/3;
	grid-row: 1/3;
}

.myt-3 {
	grid-column: 3/4;
	grid-row: 2/4;
}

.myt-4 {
	grid-column: 4/5;
	grid-row: 1/6;
}

.myt-5 {
	grid-column: 5/6;
	grid-row: 2/5;
}

.myt-6 {
	grid-column: 1/2;
	grid-row: 4/10;
}

.myt-7 {
	grid-column: 2/3;
	grid-row: 3/9;
}

.myt-8 {
	grid-column: 3/4;
	grid-row: 4/10;
}

.myt-9 {
	grid-column: 4/5;
	grid-row: 6/9;
}

.myt-10 {
	grid-column: 5/6;
	grid-row: 5/10;
}

@media only screen and (max-width: 768px) {
	.myt-grid {
		display: grid;
		grid-template-columns: 27% 40% 27.7%;
		grid-template-rows: 30px 150px 50px 250px 50px 150px 90px 130px 50px;
		gap: 20px;
	}

	.myt-grid div {
		object-fit: cover;
	}

	.myt-1 {
		grid-column: 1/2;
		grid-row: 2/4;
	}

	.myt-2 {
		grid-column: 2/3;
		grid-row: 1/3;
	}

	.myt-3 {
		grid-column: 3/4;
		grid-row: 2/4;
	}

	.myt-6 {
		grid-column: 1/2;
		grid-row: 4/6;
	}

	.myt-7 {
		grid-column: 2/3;
		grid-row: 3/5;
	}

	.myt-8 {
		grid-column: 3/4;
		grid-row: 4/6;
	}

	.myt-4 {
		grid-column: 1/2;
		grid-row: 6/9;
	}

	.myt-9 {
		grid-column: 2/3;
		grid-row: 5/7;
	}

	.myt-5 {
		grid-column: 3/4;
		grid-row: 6/8;
	}

	.myt-10 {
		grid-column: 2/3;
		grid-row: 7/10;
	}
}

@media only screen and (max-width: 425px) {
	.myt-grid {
		grid-template-columns: 27% 38% 28%;
		grid-template-rows: 30px 100px 50px 150px 50px 80px 70px 60px 10px;
		grid-gap: 15px;
	}
	.myt-grid div {
		object-fit: cover;
	}
}

@media only screen and (max-width: 375px) {
	.myt-grid {
		grid-template-columns: 27% 36.5% 28%;
		grid-gap: 12px;
	}
}

@media only screen and (max-width: 320px) {
	.myt-grid {
		grid-template-columns: 28% 36% 29%;
		grid-gap: 10px;
	}
}

.right-position-corner {
	right: 23.5%;
}

.corner-popup::after {
	content: '';
	position: absolute;
	top: calc(50% - -50%);
	/* left: calc(50% - 156px); */
	border-bottom: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #321a50;
	border-left: 15px solid transparent;
}

#navbar {
	background-color: #333;
	position: fixed;
	top: 0;
	width: 100%;
	display: block;
	transition: top 0.3s;
}

.moblileHeaderMenu {
	height: calc(100vh - 180px);
	overflow-y: scroll;
}

.mobile-scroll {
	width: calc(100vw - 20px);
}

@media only screen and (min-width: 641px) and (max-width: 767px) {
	.hero-img {
		right: -20% !important;
	}
}

.video_height {
	height: 42rem;
}
.video_blog_height {
	height: 25rem;
}

@media (min-width: 1500px) {
	.align-css {
		padding-left: calc(100% - 75%) !important;
	}
}
@media (max-width: 600px) {
	.video_height {
		height: 40vh;
	}
	.video_blog_height {
		height: 100%;
	}

    .scroll-mobile-congratulation{
        height: calc(100vh - 130px);
        overflow-y: scroll;
    }
}

@media (max-width: 420px) {
    .pricin-popup-select{
        height: calc(100vh - 240px);
        overflow-y: scroll
    }
}

@media (max-width: 400px) {
    .downGradePopup{
        overflow-y: scroll;
    height: calc(100vh - 100px);
    }
}


  
.fontFamilyQuincy{
    font-family: 'Quincy';
}

.bg-bgBlueIcon{
    background: #321A50;
}

.bg-press-img{
    background-image: url(https://images.myyogateacher.com/web_images/bg-press.jpg);
}

.scroll-cancel-modal{
    overflow-y: scroll;
    height: calc(100vh - 280px);
}


  /* scroll bar css */


  body::-webkit-scrollbar {
    width: 8px;
    background:rgba(228, 228, 228, 0.747);
  }
  
  body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(104, 104, 104, 0.4); 
    border-radius: 15px;
  }
  
  body::-webkit-scrollbar-thumb {
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8); 
    background:rgba(148, 148, 148, 0.8);
  } 