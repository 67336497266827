.custom-loading-screen {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	/* background-color: rgba(0, 0, 0, 0.5); */
	z-index: 99999;
}

.custom-loading-animation {
	width: 0;
	height: 0;
	border-radius: 50%;
	border: 1px solid #4332df;
	animation: spinner 1s infinite;
}

@keyframes spinner {
	0% {
		border: 0px solid #4332df;
		opacity: 1;
	}

	10% {
		border: 4px solid #4332df;
		opacity: 0.9;
	}

	20% {
		border: 8px solid #4332df;
		opacity: 0.8;
	}

	30% {
		border: 12px solid #4332df;
		opacity: 0.7;
	}

	40% {
		border: 16px solid #4332df;
		opacity: 0.6;
	}

	50% {
		border: 20px solid #4332df;
		opacity: 0.5;
	}

	60% {
		border: 24px solid #4332df;
		opacity: 0.4;
	}

	70% {
		border: 28px solid #4332df;
		opacity: 0.3;
	}

	80% {
		border: 32px solid #4332df;
		opacity: 0.2;
	}

	90% {
		border: 36px solid #4332df;
		opacity: 0.1;
	}

	100% {
		border: 40px solid #4332df;
		opacity: 0;
	}
}
